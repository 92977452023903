import { default as foodsLQVKarIxT6Meta } from "/web/pages/admin/al/foods.vue?macro=true";
import { default as helperjobsbdPDekOaCkMeta } from "/web/pages/admin/al/helperjobs.vue?macro=true";
import { default as itemsR6agHfolAkMeta } from "/web/pages/admin/booking/items.vue?macro=true";
import { default as locationsP5WqxhQPhwMeta } from "/web/pages/admin/booking/locations.vue?macro=true";
import { default as ordersjzscm4Lqb4Meta } from "/web/pages/admin/booking/orders.vue?macro=true";
import { default as configBWXKoQ1oyUMeta } from "/web/pages/admin/config.vue?macro=true";
import { default as coursek2YPOZCzIFMeta } from "/web/pages/admin/discgolf/course.vue?macro=true";
import { default as infosxFuC8j9rsjMeta } from "/web/pages/admin/discgolf/infos.vue?macro=true";
import { default as playersGxL6IuuOVzMeta } from "/web/pages/admin/discgolf/players.vue?macro=true";
import { default as settlementsG2C2UigxQMeta } from "/web/pages/admin/discgolf/settlement.vue?macro=true";
import { default as imageseY4kM7tWtdMeta } from "/web/pages/admin/images.vue?macro=true";
import { default as indexm1iS6vAHYOMeta } from "/web/pages/admin/index.vue?macro=true";
import { default as init_45slotsZJnXVjBaIdMeta } from "/web/pages/admin/schedule/init-slots.vue?macro=true";
import { default as mixedsK3zRvDB4MMeta } from "/web/pages/admin/schedule/ko/mixed.vue?macro=true";
import { default as openMB2kKekZ6yMeta } from "/web/pages/admin/schedule/ko/open.vue?macro=true";
import { default as pool_45seedingsUXTdX0xaruMeta } from "/web/pages/admin/schedule/pool-seedings.vue?macro=true";
import { default as swisswmTk482w4zMeta } from "/web/pages/admin/schedule/swiss.vue?macro=true";
import { default as spiritCWGj0lsWWyMeta } from "/web/pages/admin/spirit.vue?macro=true";
import { default as infosTc6VvDqBhXMeta } from "/web/pages/admin/tournament/infos.vue?macro=true";
import { default as playersmUCmkeLAO8Meta } from "/web/pages/admin/tournament/players.vue?macro=true";
import { default as teamsYdrOLfH6DxMeta } from "/web/pages/admin/tournament/teams.vue?macro=true";
import { default as rolestlwt9gxFJAMeta } from "/web/pages/admin/user-management/roles.vue?macro=true";
import { default as teammanager17Y74oUrsXMeta } from "/web/pages/admin/user-management/teammanager.vue?macro=true";
import { default as usershfmIqGwoh0Meta } from "/web/pages/admin/user-management/users.vue?macro=true";
import { default as foodsd3AneWKFU7Meta } from "/web/pages/al/foods.vue?macro=true";
import { default as helperjobsEFL5iIBMDFMeta } from "/web/pages/al/helperjobs.vue?macro=true";
import { default as indexIMpSCo7EiqMeta } from "/web/pages/al/index.vue?macro=true";
import { default as bookingyuo6VUnDO2Meta } from "/web/pages/booking.vue?macro=true";
import { default as courseRP5gmemWlLMeta } from "/web/pages/discgolf/course.vue?macro=true";
import { default as indexfkKqiCEig3Meta } from "/web/pages/discgolf/index.vue?macro=true";
import { default as tournamentY6nu3OPl71Meta } from "/web/pages/discgolf/tournament.vue?macro=true";
import { default as indexRB1KviEwVnMeta } from "/web/pages/index.vue?macro=true";
import { default as mapoGHLDqd6bjMeta } from "/web/pages/map.vue?macro=true";
import { default as new_45playerX6IfHzwVgMMeta } from "/web/pages/new-player.vue?macro=true";
import { default as new_45spiritzSN203YSGMMeta } from "/web/pages/new-spirit.vue?macro=true";
import { default as registrationbL8JRbTJLlMeta } from "/web/pages/registration.vue?macro=true";
import { default as swiss_45drawusDXCERhCBMeta } from "/web/pages/schedule/swiss-draw.vue?macro=true";
import { default as timetable4mNKg9nzLWMeta } from "/web/pages/schedule/timetable.vue?macro=true";
import { default as settingsmUa3shRRHrMeta } from "/web/pages/settings.vue?macro=true";
import { default as statswF6cwG3mUsMeta } from "/web/pages/stats.vue?macro=true";
import { default as indexQRKGaGtWzLMeta } from "/web/pages/teammanager/index.vue?macro=true";
import { default as playersHdsKmzZUisMeta } from "/web/pages/teammanager/players.vue?macro=true";
import { default as settlementu6uDLL1vqiMeta } from "/web/pages/teammanager/settlement.vue?macro=true";
import { default as spirit_45overviewhyfOJrkLFyMeta } from "/web/pages/teammanager/spirit-overview.vue?macro=true";
import { default as teamspbw9a7jOLJMeta } from "/web/pages/teams.vue?macro=true";
import { default as tournamentRJ1flIeBWNMeta } from "/web/pages/tournament.vue?macro=true";
import { default as _91token_933M9x4eidPgMeta } from "/web/pages/user/confirm-password-reset/[token].vue?macro=true";
import { default as connectorFUSok5yTNoMeta } from "/web/pages/user/connector.vue?macro=true";
import { default as login3Dbg61UC5WMeta } from "/web/pages/user/login.vue?macro=true";
import { default as no_45accessUwX1mmWzK8Meta } from "/web/pages/user/no-access.vue?macro=true";
import { default as profile9qZnZaf4uCMeta } from "/web/pages/user/profile.vue?macro=true";
import { default as reset_45passwordnVFZ7Rq0fYMeta } from "/web/pages/user/reset-password.vue?macro=true";
import { default as signupvTcibqvdDoMeta } from "/web/pages/user/signup.vue?macro=true";
export default [
  {
    name: foodsLQVKarIxT6Meta?.name ?? "admin-al-foods",
    path: foodsLQVKarIxT6Meta?.path ?? "/admin/al/foods",
    meta: foodsLQVKarIxT6Meta || {},
    alias: foodsLQVKarIxT6Meta?.alias || [],
    redirect: foodsLQVKarIxT6Meta?.redirect,
    component: () => import("/web/pages/admin/al/foods.vue").then(m => m.default || m)
  },
  {
    name: helperjobsbdPDekOaCkMeta?.name ?? "admin-al-helperjobs",
    path: helperjobsbdPDekOaCkMeta?.path ?? "/admin/al/helperjobs",
    meta: helperjobsbdPDekOaCkMeta || {},
    alias: helperjobsbdPDekOaCkMeta?.alias || [],
    redirect: helperjobsbdPDekOaCkMeta?.redirect,
    component: () => import("/web/pages/admin/al/helperjobs.vue").then(m => m.default || m)
  },
  {
    name: itemsR6agHfolAkMeta?.name ?? "admin-booking-items",
    path: itemsR6agHfolAkMeta?.path ?? "/admin/booking/items",
    meta: itemsR6agHfolAkMeta || {},
    alias: itemsR6agHfolAkMeta?.alias || [],
    redirect: itemsR6agHfolAkMeta?.redirect,
    component: () => import("/web/pages/admin/booking/items.vue").then(m => m.default || m)
  },
  {
    name: locationsP5WqxhQPhwMeta?.name ?? "admin-booking-locations",
    path: locationsP5WqxhQPhwMeta?.path ?? "/admin/booking/locations",
    meta: locationsP5WqxhQPhwMeta || {},
    alias: locationsP5WqxhQPhwMeta?.alias || [],
    redirect: locationsP5WqxhQPhwMeta?.redirect,
    component: () => import("/web/pages/admin/booking/locations.vue").then(m => m.default || m)
  },
  {
    name: ordersjzscm4Lqb4Meta?.name ?? "admin-booking-orders",
    path: ordersjzscm4Lqb4Meta?.path ?? "/admin/booking/orders",
    meta: ordersjzscm4Lqb4Meta || {},
    alias: ordersjzscm4Lqb4Meta?.alias || [],
    redirect: ordersjzscm4Lqb4Meta?.redirect,
    component: () => import("/web/pages/admin/booking/orders.vue").then(m => m.default || m)
  },
  {
    name: configBWXKoQ1oyUMeta?.name ?? "admin-config",
    path: configBWXKoQ1oyUMeta?.path ?? "/admin/config",
    meta: configBWXKoQ1oyUMeta || {},
    alias: configBWXKoQ1oyUMeta?.alias || [],
    redirect: configBWXKoQ1oyUMeta?.redirect,
    component: () => import("/web/pages/admin/config.vue").then(m => m.default || m)
  },
  {
    name: coursek2YPOZCzIFMeta?.name ?? "admin-discgolf-course",
    path: coursek2YPOZCzIFMeta?.path ?? "/admin/discgolf/course",
    meta: coursek2YPOZCzIFMeta || {},
    alias: coursek2YPOZCzIFMeta?.alias || [],
    redirect: coursek2YPOZCzIFMeta?.redirect,
    component: () => import("/web/pages/admin/discgolf/course.vue").then(m => m.default || m)
  },
  {
    name: infosxFuC8j9rsjMeta?.name ?? "admin-discgolf-infos",
    path: infosxFuC8j9rsjMeta?.path ?? "/admin/discgolf/infos",
    meta: infosxFuC8j9rsjMeta || {},
    alias: infosxFuC8j9rsjMeta?.alias || [],
    redirect: infosxFuC8j9rsjMeta?.redirect,
    component: () => import("/web/pages/admin/discgolf/infos.vue").then(m => m.default || m)
  },
  {
    name: playersGxL6IuuOVzMeta?.name ?? "admin-discgolf-players",
    path: playersGxL6IuuOVzMeta?.path ?? "/admin/discgolf/players",
    meta: playersGxL6IuuOVzMeta || {},
    alias: playersGxL6IuuOVzMeta?.alias || [],
    redirect: playersGxL6IuuOVzMeta?.redirect,
    component: () => import("/web/pages/admin/discgolf/players.vue").then(m => m.default || m)
  },
  {
    name: settlementsG2C2UigxQMeta?.name ?? "admin-discgolf-settlement",
    path: settlementsG2C2UigxQMeta?.path ?? "/admin/discgolf/settlement",
    meta: settlementsG2C2UigxQMeta || {},
    alias: settlementsG2C2UigxQMeta?.alias || [],
    redirect: settlementsG2C2UigxQMeta?.redirect,
    component: () => import("/web/pages/admin/discgolf/settlement.vue").then(m => m.default || m)
  },
  {
    name: imageseY4kM7tWtdMeta?.name ?? "admin-images",
    path: imageseY4kM7tWtdMeta?.path ?? "/admin/images",
    meta: imageseY4kM7tWtdMeta || {},
    alias: imageseY4kM7tWtdMeta?.alias || [],
    redirect: imageseY4kM7tWtdMeta?.redirect,
    component: () => import("/web/pages/admin/images.vue").then(m => m.default || m)
  },
  {
    name: indexm1iS6vAHYOMeta?.name ?? "admin",
    path: indexm1iS6vAHYOMeta?.path ?? "/admin",
    meta: indexm1iS6vAHYOMeta || {},
    alias: indexm1iS6vAHYOMeta?.alias || [],
    redirect: indexm1iS6vAHYOMeta?.redirect,
    component: () => import("/web/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: init_45slotsZJnXVjBaIdMeta?.name ?? "admin-schedule-init-slots",
    path: init_45slotsZJnXVjBaIdMeta?.path ?? "/admin/schedule/init-slots",
    meta: init_45slotsZJnXVjBaIdMeta || {},
    alias: init_45slotsZJnXVjBaIdMeta?.alias || [],
    redirect: init_45slotsZJnXVjBaIdMeta?.redirect,
    component: () => import("/web/pages/admin/schedule/init-slots.vue").then(m => m.default || m)
  },
  {
    name: mixedsK3zRvDB4MMeta?.name ?? "admin-schedule-ko-mixed",
    path: mixedsK3zRvDB4MMeta?.path ?? "/admin/schedule/ko/mixed",
    meta: mixedsK3zRvDB4MMeta || {},
    alias: mixedsK3zRvDB4MMeta?.alias || [],
    redirect: mixedsK3zRvDB4MMeta?.redirect,
    component: () => import("/web/pages/admin/schedule/ko/mixed.vue").then(m => m.default || m)
  },
  {
    name: openMB2kKekZ6yMeta?.name ?? "admin-schedule-ko-open",
    path: openMB2kKekZ6yMeta?.path ?? "/admin/schedule/ko/open",
    meta: openMB2kKekZ6yMeta || {},
    alias: openMB2kKekZ6yMeta?.alias || [],
    redirect: openMB2kKekZ6yMeta?.redirect,
    component: () => import("/web/pages/admin/schedule/ko/open.vue").then(m => m.default || m)
  },
  {
    name: pool_45seedingsUXTdX0xaruMeta?.name ?? "admin-schedule-pool-seedings",
    path: pool_45seedingsUXTdX0xaruMeta?.path ?? "/admin/schedule/pool-seedings",
    meta: pool_45seedingsUXTdX0xaruMeta || {},
    alias: pool_45seedingsUXTdX0xaruMeta?.alias || [],
    redirect: pool_45seedingsUXTdX0xaruMeta?.redirect,
    component: () => import("/web/pages/admin/schedule/pool-seedings.vue").then(m => m.default || m)
  },
  {
    name: swisswmTk482w4zMeta?.name ?? "admin-schedule-swiss",
    path: swisswmTk482w4zMeta?.path ?? "/admin/schedule/swiss",
    meta: swisswmTk482w4zMeta || {},
    alias: swisswmTk482w4zMeta?.alias || [],
    redirect: swisswmTk482w4zMeta?.redirect,
    component: () => import("/web/pages/admin/schedule/swiss.vue").then(m => m.default || m)
  },
  {
    name: spiritCWGj0lsWWyMeta?.name ?? "admin-spirit",
    path: spiritCWGj0lsWWyMeta?.path ?? "/admin/spirit",
    meta: spiritCWGj0lsWWyMeta || {},
    alias: spiritCWGj0lsWWyMeta?.alias || [],
    redirect: spiritCWGj0lsWWyMeta?.redirect,
    component: () => import("/web/pages/admin/spirit.vue").then(m => m.default || m)
  },
  {
    name: infosTc6VvDqBhXMeta?.name ?? "admin-tournament-infos",
    path: infosTc6VvDqBhXMeta?.path ?? "/admin/tournament/infos",
    meta: infosTc6VvDqBhXMeta || {},
    alias: infosTc6VvDqBhXMeta?.alias || [],
    redirect: infosTc6VvDqBhXMeta?.redirect,
    component: () => import("/web/pages/admin/tournament/infos.vue").then(m => m.default || m)
  },
  {
    name: playersmUCmkeLAO8Meta?.name ?? "admin-tournament-players",
    path: playersmUCmkeLAO8Meta?.path ?? "/admin/tournament/players",
    meta: playersmUCmkeLAO8Meta || {},
    alias: playersmUCmkeLAO8Meta?.alias || [],
    redirect: playersmUCmkeLAO8Meta?.redirect,
    component: () => import("/web/pages/admin/tournament/players.vue").then(m => m.default || m)
  },
  {
    name: teamsYdrOLfH6DxMeta?.name ?? "admin-tournament-teams",
    path: teamsYdrOLfH6DxMeta?.path ?? "/admin/tournament/teams",
    meta: teamsYdrOLfH6DxMeta || {},
    alias: teamsYdrOLfH6DxMeta?.alias || [],
    redirect: teamsYdrOLfH6DxMeta?.redirect,
    component: () => import("/web/pages/admin/tournament/teams.vue").then(m => m.default || m)
  },
  {
    name: rolestlwt9gxFJAMeta?.name ?? "admin-user-management-roles",
    path: rolestlwt9gxFJAMeta?.path ?? "/admin/user-management/roles",
    meta: rolestlwt9gxFJAMeta || {},
    alias: rolestlwt9gxFJAMeta?.alias || [],
    redirect: rolestlwt9gxFJAMeta?.redirect,
    component: () => import("/web/pages/admin/user-management/roles.vue").then(m => m.default || m)
  },
  {
    name: teammanager17Y74oUrsXMeta?.name ?? "admin-user-management-teammanager",
    path: teammanager17Y74oUrsXMeta?.path ?? "/admin/user-management/teammanager",
    meta: teammanager17Y74oUrsXMeta || {},
    alias: teammanager17Y74oUrsXMeta?.alias || [],
    redirect: teammanager17Y74oUrsXMeta?.redirect,
    component: () => import("/web/pages/admin/user-management/teammanager.vue").then(m => m.default || m)
  },
  {
    name: usershfmIqGwoh0Meta?.name ?? "admin-user-management-users",
    path: usershfmIqGwoh0Meta?.path ?? "/admin/user-management/users",
    meta: usershfmIqGwoh0Meta || {},
    alias: usershfmIqGwoh0Meta?.alias || [],
    redirect: usershfmIqGwoh0Meta?.redirect,
    component: () => import("/web/pages/admin/user-management/users.vue").then(m => m.default || m)
  },
  {
    name: foodsd3AneWKFU7Meta?.name ?? "al-foods",
    path: foodsd3AneWKFU7Meta?.path ?? "/al/foods",
    meta: foodsd3AneWKFU7Meta || {},
    alias: foodsd3AneWKFU7Meta?.alias || [],
    redirect: foodsd3AneWKFU7Meta?.redirect,
    component: () => import("/web/pages/al/foods.vue").then(m => m.default || m)
  },
  {
    name: helperjobsEFL5iIBMDFMeta?.name ?? "al-helperjobs",
    path: helperjobsEFL5iIBMDFMeta?.path ?? "/al/helperjobs",
    meta: helperjobsEFL5iIBMDFMeta || {},
    alias: helperjobsEFL5iIBMDFMeta?.alias || [],
    redirect: helperjobsEFL5iIBMDFMeta?.redirect,
    component: () => import("/web/pages/al/helperjobs.vue").then(m => m.default || m)
  },
  {
    name: indexIMpSCo7EiqMeta?.name ?? "al",
    path: indexIMpSCo7EiqMeta?.path ?? "/al",
    meta: indexIMpSCo7EiqMeta || {},
    alias: indexIMpSCo7EiqMeta?.alias || [],
    redirect: indexIMpSCo7EiqMeta?.redirect,
    component: () => import("/web/pages/al/index.vue").then(m => m.default || m)
  },
  {
    name: bookingyuo6VUnDO2Meta?.name ?? "booking",
    path: bookingyuo6VUnDO2Meta?.path ?? "/booking",
    meta: bookingyuo6VUnDO2Meta || {},
    alias: bookingyuo6VUnDO2Meta?.alias || [],
    redirect: bookingyuo6VUnDO2Meta?.redirect,
    component: () => import("/web/pages/booking.vue").then(m => m.default || m)
  },
  {
    name: courseRP5gmemWlLMeta?.name ?? "discgolf-course",
    path: courseRP5gmemWlLMeta?.path ?? "/discgolf/course",
    meta: courseRP5gmemWlLMeta || {},
    alias: courseRP5gmemWlLMeta?.alias || [],
    redirect: courseRP5gmemWlLMeta?.redirect,
    component: () => import("/web/pages/discgolf/course.vue").then(m => m.default || m)
  },
  {
    name: indexfkKqiCEig3Meta?.name ?? "discgolf",
    path: indexfkKqiCEig3Meta?.path ?? "/discgolf",
    meta: indexfkKqiCEig3Meta || {},
    alias: indexfkKqiCEig3Meta?.alias || [],
    redirect: indexfkKqiCEig3Meta?.redirect,
    component: () => import("/web/pages/discgolf/index.vue").then(m => m.default || m)
  },
  {
    name: tournamentY6nu3OPl71Meta?.name ?? "discgolf-tournament",
    path: tournamentY6nu3OPl71Meta?.path ?? "/discgolf/tournament",
    meta: tournamentY6nu3OPl71Meta || {},
    alias: tournamentY6nu3OPl71Meta?.alias || [],
    redirect: tournamentY6nu3OPl71Meta?.redirect,
    component: () => import("/web/pages/discgolf/tournament.vue").then(m => m.default || m)
  },
  {
    name: indexRB1KviEwVnMeta?.name ?? "index",
    path: indexRB1KviEwVnMeta?.path ?? "/",
    meta: indexRB1KviEwVnMeta || {},
    alias: indexRB1KviEwVnMeta?.alias || [],
    redirect: indexRB1KviEwVnMeta?.redirect,
    component: () => import("/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mapoGHLDqd6bjMeta?.name ?? "map",
    path: mapoGHLDqd6bjMeta?.path ?? "/map",
    meta: mapoGHLDqd6bjMeta || {},
    alias: mapoGHLDqd6bjMeta?.alias || [],
    redirect: mapoGHLDqd6bjMeta?.redirect,
    component: () => import("/web/pages/map.vue").then(m => m.default || m)
  },
  {
    name: new_45playerX6IfHzwVgMMeta?.name ?? "new-player",
    path: new_45playerX6IfHzwVgMMeta?.path ?? "/new-player",
    meta: new_45playerX6IfHzwVgMMeta || {},
    alias: new_45playerX6IfHzwVgMMeta?.alias || [],
    redirect: new_45playerX6IfHzwVgMMeta?.redirect,
    component: () => import("/web/pages/new-player.vue").then(m => m.default || m)
  },
  {
    name: new_45spiritzSN203YSGMMeta?.name ?? "new-spirit",
    path: new_45spiritzSN203YSGMMeta?.path ?? "/new-spirit",
    meta: new_45spiritzSN203YSGMMeta || {},
    alias: new_45spiritzSN203YSGMMeta?.alias || [],
    redirect: new_45spiritzSN203YSGMMeta?.redirect,
    component: () => import("/web/pages/new-spirit.vue").then(m => m.default || m)
  },
  {
    name: registrationbL8JRbTJLlMeta?.name ?? "registration",
    path: registrationbL8JRbTJLlMeta?.path ?? "/registration",
    meta: registrationbL8JRbTJLlMeta || {},
    alias: registrationbL8JRbTJLlMeta?.alias || [],
    redirect: registrationbL8JRbTJLlMeta?.redirect,
    component: () => import("/web/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: swiss_45drawusDXCERhCBMeta?.name ?? "schedule-swiss-draw",
    path: swiss_45drawusDXCERhCBMeta?.path ?? "/schedule/swiss-draw",
    meta: swiss_45drawusDXCERhCBMeta || {},
    alias: swiss_45drawusDXCERhCBMeta?.alias || [],
    redirect: swiss_45drawusDXCERhCBMeta?.redirect,
    component: () => import("/web/pages/schedule/swiss-draw.vue").then(m => m.default || m)
  },
  {
    name: timetable4mNKg9nzLWMeta?.name ?? "schedule-timetable",
    path: timetable4mNKg9nzLWMeta?.path ?? "/schedule/timetable",
    meta: timetable4mNKg9nzLWMeta || {},
    alias: timetable4mNKg9nzLWMeta?.alias || [],
    redirect: timetable4mNKg9nzLWMeta?.redirect,
    component: () => import("/web/pages/schedule/timetable.vue").then(m => m.default || m)
  },
  {
    name: settingsmUa3shRRHrMeta?.name ?? "settings",
    path: settingsmUa3shRRHrMeta?.path ?? "/settings",
    meta: settingsmUa3shRRHrMeta || {},
    alias: settingsmUa3shRRHrMeta?.alias || [],
    redirect: settingsmUa3shRRHrMeta?.redirect,
    component: () => import("/web/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: statswF6cwG3mUsMeta?.name ?? "stats",
    path: statswF6cwG3mUsMeta?.path ?? "/stats",
    meta: statswF6cwG3mUsMeta || {},
    alias: statswF6cwG3mUsMeta?.alias || [],
    redirect: statswF6cwG3mUsMeta?.redirect,
    component: () => import("/web/pages/stats.vue").then(m => m.default || m)
  },
  {
    name: indexQRKGaGtWzLMeta?.name ?? "teammanager",
    path: indexQRKGaGtWzLMeta?.path ?? "/teammanager",
    meta: indexQRKGaGtWzLMeta || {},
    alias: indexQRKGaGtWzLMeta?.alias || [],
    redirect: indexQRKGaGtWzLMeta?.redirect,
    component: () => import("/web/pages/teammanager/index.vue").then(m => m.default || m)
  },
  {
    name: playersHdsKmzZUisMeta?.name ?? "teammanager-players",
    path: playersHdsKmzZUisMeta?.path ?? "/teammanager/players",
    meta: playersHdsKmzZUisMeta || {},
    alias: playersHdsKmzZUisMeta?.alias || [],
    redirect: playersHdsKmzZUisMeta?.redirect,
    component: () => import("/web/pages/teammanager/players.vue").then(m => m.default || m)
  },
  {
    name: settlementu6uDLL1vqiMeta?.name ?? "teammanager-settlement",
    path: settlementu6uDLL1vqiMeta?.path ?? "/teammanager/settlement",
    meta: settlementu6uDLL1vqiMeta || {},
    alias: settlementu6uDLL1vqiMeta?.alias || [],
    redirect: settlementu6uDLL1vqiMeta?.redirect,
    component: () => import("/web/pages/teammanager/settlement.vue").then(m => m.default || m)
  },
  {
    name: spirit_45overviewhyfOJrkLFyMeta?.name ?? "teammanager-spirit-overview",
    path: spirit_45overviewhyfOJrkLFyMeta?.path ?? "/teammanager/spirit-overview",
    meta: spirit_45overviewhyfOJrkLFyMeta || {},
    alias: spirit_45overviewhyfOJrkLFyMeta?.alias || [],
    redirect: spirit_45overviewhyfOJrkLFyMeta?.redirect,
    component: () => import("/web/pages/teammanager/spirit-overview.vue").then(m => m.default || m)
  },
  {
    name: teamspbw9a7jOLJMeta?.name ?? "teams",
    path: teamspbw9a7jOLJMeta?.path ?? "/teams",
    meta: teamspbw9a7jOLJMeta || {},
    alias: teamspbw9a7jOLJMeta?.alias || [],
    redirect: teamspbw9a7jOLJMeta?.redirect,
    component: () => import("/web/pages/teams.vue").then(m => m.default || m)
  },
  {
    name: tournamentRJ1flIeBWNMeta?.name ?? "tournament",
    path: tournamentRJ1flIeBWNMeta?.path ?? "/tournament",
    meta: tournamentRJ1flIeBWNMeta || {},
    alias: tournamentRJ1flIeBWNMeta?.alias || [],
    redirect: tournamentRJ1flIeBWNMeta?.redirect,
    component: () => import("/web/pages/tournament.vue").then(m => m.default || m)
  },
  {
    name: _91token_933M9x4eidPgMeta?.name ?? "user-confirm-password-reset-token",
    path: _91token_933M9x4eidPgMeta?.path ?? "/user/confirm-password-reset/:token()",
    meta: _91token_933M9x4eidPgMeta || {},
    alias: _91token_933M9x4eidPgMeta?.alias || [],
    redirect: _91token_933M9x4eidPgMeta?.redirect,
    component: () => import("/web/pages/user/confirm-password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: connectorFUSok5yTNoMeta?.name ?? "user-connector",
    path: connectorFUSok5yTNoMeta?.path ?? "/user/connector",
    meta: connectorFUSok5yTNoMeta || {},
    alias: connectorFUSok5yTNoMeta?.alias || [],
    redirect: connectorFUSok5yTNoMeta?.redirect,
    component: () => import("/web/pages/user/connector.vue").then(m => m.default || m)
  },
  {
    name: login3Dbg61UC5WMeta?.name ?? "user-login",
    path: login3Dbg61UC5WMeta?.path ?? "/user/login",
    meta: login3Dbg61UC5WMeta || {},
    alias: login3Dbg61UC5WMeta?.alias || [],
    redirect: login3Dbg61UC5WMeta?.redirect,
    component: () => import("/web/pages/user/login.vue").then(m => m.default || m)
  },
  {
    name: no_45accessUwX1mmWzK8Meta?.name ?? "user-no-access",
    path: no_45accessUwX1mmWzK8Meta?.path ?? "/user/no-access",
    meta: no_45accessUwX1mmWzK8Meta || {},
    alias: no_45accessUwX1mmWzK8Meta?.alias || [],
    redirect: no_45accessUwX1mmWzK8Meta?.redirect,
    component: () => import("/web/pages/user/no-access.vue").then(m => m.default || m)
  },
  {
    name: profile9qZnZaf4uCMeta?.name ?? "user-profile",
    path: profile9qZnZaf4uCMeta?.path ?? "/user/profile",
    meta: profile9qZnZaf4uCMeta || {},
    alias: profile9qZnZaf4uCMeta?.alias || [],
    redirect: profile9qZnZaf4uCMeta?.redirect,
    component: () => import("/web/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordnVFZ7Rq0fYMeta?.name ?? "user-reset-password",
    path: reset_45passwordnVFZ7Rq0fYMeta?.path ?? "/user/reset-password",
    meta: reset_45passwordnVFZ7Rq0fYMeta || {},
    alias: reset_45passwordnVFZ7Rq0fYMeta?.alias || [],
    redirect: reset_45passwordnVFZ7Rq0fYMeta?.redirect,
    component: () => import("/web/pages/user/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signupvTcibqvdDoMeta?.name ?? "user-signup",
    path: signupvTcibqvdDoMeta?.path ?? "/user/signup",
    meta: signupvTcibqvdDoMeta || {},
    alias: signupvTcibqvdDoMeta?.alias || [],
    redirect: signupvTcibqvdDoMeta?.redirect,
    component: () => import("/web/pages/user/signup.vue").then(m => m.default || m)
  }
]