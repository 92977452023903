/**
* This file was @generated using pocketbase-typegen
*/

import type PocketBase from 'pocketbase'
import type { RecordService } from 'pocketbase'

export enum Collections {
  Config = 'config',
  DiscgolfCourse = 'discgolf_course',
  DiscgolfInfos = 'discgolf_infos',
  Images = 'images',
  Items = 'items',
  Locations = 'locations',
  Matchups = 'matchups',
  Orders = 'orders',
  Players = 'players',
  PoolElements = 'pool_elements',
  Pools = 'pools',
  Roles = 'roles',
  Slots = 'slots',
  SlottedMatchups = 'slotted_matchups',
  Spirit = 'spirit',
  StatsPlaylists = 'stats_playlists',
  TeamRegistrations = 'team_registrations',
  Teams = 'teams',
  TournamentInfos = 'tournament_infos',
  Users = 'users',
}

// Alias types for improved usability
export type IsoDateString = string
export type RecordIdString = string
export type HTMLString = string

// System fields
export type BaseSystemFields<T = never> = {
	id: RecordIdString
	created: IsoDateString
	updated: IsoDateString
	collectionId: string
	collectionName: Collections
	expand?: T
}

export type AuthSystemFields<T = never> = {
	email: string
	emailVisibility: boolean
	username: string
	verified: boolean
} & BaseSystemFields<T>

// Record types for each collection

export type ConfigRecord = {
	arsludendi_registration_pw?: string
	ko_phase_mixed?: boolean
	ko_phase_open?: boolean
	playersfee?: number
	schedule_initialized?: boolean
	spirit_entry_active?: boolean
	spirit_entry_message?: string
	team_registration_active?: boolean
	teammanager_settlement_active?: boolean
	teammanager_settlement_message?: string
	teammanager_spirit_overview_active?: boolean
	teammanager_spirit_overview_message?: string
	user_login_active?: boolean
	user_login_message?: string
	user_registration_active?: boolean
	user_registration_message?: string
}

export type DiscgolfCourseRecord = {
	image?: string
	number?: number
}

export type DiscgolfInfosRecord = {
	order?: number
	text?: HTMLString
	title?: string
}

export enum FoodsCategoryOptions {
	"Salad" = "Salad",
	"Cake" = "Cake",
	"Breakfast" = "Breakfast",
}

export enum FoodsDayOptions {
	"Saturday" = "Saturday",
	"Sunday" = "Sunday",
}
export type FoodsRecord = {
	category?: FoodsCategoryOptions
	day?: FoodsDayOptions
	name?: string
	user?: RecordIdString
}

export enum HelperjobsCategoryOptions {
	"Drinks" = "Drinks",
	"Food" = "Food",
	"Maintenance" = "Maintenance",
	"General" = "General",
	"Party" = "Party",
}
export type HelperjobsRecord = {
	all?: boolean
	category?: HelperjobsCategoryOptions
	chiefs?: RecordIdString[]
	count?: number
	description?: string
	end?: IsoDateString
	name?: string
	persons?: RecordIdString[]
	score?: number
	start?: IsoDateString
}

export enum HelperjobsViewCategoryOptions {
	"Drinks" = "Drinks",
	"Food" = "Food",
	"Maintenance" = "Maintenance",
	"General" = "General",
	"Party" = "Party",
}
export type HelperjobsViewRecord<Tchief_names_array = unknown, Tperson_names_array = unknown> = {
	all?: boolean
	category?: HelperjobsViewCategoryOptions
	chief_names_array?: null | Tchief_names_array
	count?: number
	description?: string
	end?: IsoDateString
	name?: string
	person_names_array?: null | Tperson_names_array
	score?: number
	start?: IsoDateString
}

export type ImagesRecord = {
	barcode?: string
	big_map?: string
	small_map?: string
}

export type ItemsRecord = {
	icon?: string
	locations?: RecordIdString[]
	name?: string
	price?: number
}

export type LocationsRecord = {
	name?: string
}

export type MatchupsRecord = {
	ko_winner_matchup?: boolean;
	prev_ko_matchup_a?: RecordIdString;
	prev_ko_matchup_b?: RecordIdString;
	resulting_rank?: number;
	score_a?: number;
	score_b?: number;
	team_a?: RecordIdString;
	team_b?: RecordIdString;
};

export enum NotificationsSeverityOptions {
	"success" = "success",
	"info" = "info",
	"warn" = "warn",
	"error" = "error",
	"secondary" = "secondary",
	"contrast" = "contrast",
}
export type NotificationsRecord = {
	active?: boolean
	closable?: boolean
	severity?: NotificationsSeverityOptions
	text?: HTMLString
}

export type OrdersRecord = {
	booker?: RecordIdString
	count: number
	deletetime?: IsoDateString
	item: RecordIdString
	player: RecordIdString
	sum?: number
}

export type PlayersRecord = {
	booking_id: number
	name: string
	team: RecordIdString
}

export type PoolElementsRecord = {
  pool?: RecordIdString;
  seeding?: number;
  team: RecordIdString;
};

export enum PoolsDivisionOptions {
	"mixed" = "mixed",
	"open" = "open",
}
export type PoolsRecord = {
	division: PoolsDivisionOptions
	name: string
}

export enum RolesRolesOptions {
	"admin" = "admin",
	"booker" = "booker",
	"stats" = "stats",
	"teammanager" = "teammanager",
	"worker" = "worker",
}
export type RolesRecord = {
	roles?: RolesRolesOptions[]
	user?: RecordIdString
}

export type SlotsRecord = {
	duration: number
	field: number
	matchup?: RecordIdString
	pool?: RecordIdString
	start: number
}

export type SlottedMatchupsRecord = {
	duration: number;
	field: number;
	ko_winner_matchup?: boolean;
	matchup_id?: RecordIdString;
	pool?: RecordIdString;
	prev_ko_matchup_a?: RecordIdString;
	prev_ko_matchup_b?: RecordIdString;
	resulting_rank?: number;
	score_a?: number;
	score_b?: number;
	start: number;
	team_a?: RecordIdString;
	team_b?: RecordIdString;
};

export type SpiritRecord = {
	comment?: string
	communication?: number
	fair_mindedness?: number
	fouls_and_body_contact?: number
	opponent: RecordIdString
	positive_attitude_and_self_control?: number
	rules_knowledge_and_use?: number
	team: RecordIdString
	total_score?: number
}

export type StatsPlaylistsRecord<Tconfig = unknown> = {
	config: null | Tconfig
	name: string
	version: number
}

export enum TeamRegistrationsDivisionOptions {
	"mixed" = "mixed",
	"open" = "open",
}
export type TeamRegistrationsRecord = {
	competition_level?: number
	contact_person?: string
	division?: TeamRegistrationsDivisionOptions
	email?: string
	party_level?: number
	reason?: string
	team_name?: string
	town?: string
}

export enum TeamsDivisionOptions {
	"mixed" = "mixed",
	"open" = "open",
}
export type TeamsRecord = {
	division: TeamsDivisionOptions
	name: string
	teammanager?: RecordIdString[]
}

export type TournamentInfosRecord = {
	order?: number
	text?: HTMLString
	title?: string
}

export type UsersRecord = {
	avatar?: string
	name?: string
	player?: RecordIdString
}

// Response types include system fields and match responses from the PocketBase API
export type ConfigResponse<Texpand = unknown> = Required<ConfigRecord> &
  BaseSystemFields<Texpand>;
export type DiscgolfCourseResponse<Texpand = unknown> =
  Required<DiscgolfCourseRecord> & BaseSystemFields<Texpand>;
export type DiscgolfInfosResponse<Texpand = unknown> =
  Required<DiscgolfInfosRecord> & BaseSystemFields<Texpand>;
export type ImagesResponse<Texpand = unknown> = Required<ImagesRecord> &
  BaseSystemFields<Texpand>;
export type ItemsResponse<Texpand = unknown> = Required<ItemsRecord> &
  BaseSystemFields<Texpand>;
export type LocationsResponse<Texpand = unknown> = Required<LocationsRecord> &
  BaseSystemFields<Texpand>;
export type MatchupsResponse<Texpand = unknown> = Required<MatchupsRecord> &
  BaseSystemFields<Texpand>;
export type OrdersResponse<Texpand = unknown> = Required<OrdersRecord> &
  BaseSystemFields<Texpand>;
export type PlayersResponse<Texpand = unknown> = Required<PlayersRecord> &
  BaseSystemFields<Texpand>;
export type PoolElementsResponse<Texpand = unknown> =
  Required<PoolElementsRecord> & BaseSystemFields<Texpand>;
export type PoolsResponse<Texpand = unknown> = Required<PoolsRecord> &
  BaseSystemFields<Texpand>;
export type RolesResponse<Texpand = unknown> = Required<RolesRecord> &
  BaseSystemFields<Texpand>;
export type SlotsResponse<Texpand = unknown> = Required<SlotsRecord> &
  BaseSystemFields<Texpand>;
export type SlottedMatchupsResponse<Texpand = unknown> =
  Required<SlottedMatchupsRecord> & BaseSystemFields<Texpand>;
export type SpiritResponse<Texpand = unknown> = Required<SpiritRecord> &
  BaseSystemFields<Texpand>;
export type StatsPlaylistsResponse<
  Tconfig = unknown,
  Texpand = unknown,
> = Required<StatsPlaylistsRecord<Tconfig>> & BaseSystemFields<Texpand>;
export type TeamRegistrationsResponse<Texpand = unknown> =
  Required<TeamRegistrationsRecord> & BaseSystemFields<Texpand>;
export type TeamsResponse<Texpand = unknown> = Required<TeamsRecord> &
  BaseSystemFields<Texpand>;
export type TournamentInfosResponse<Texpand = unknown> =
  Required<TournamentInfosRecord> & BaseSystemFields<Texpand>;
export type UsersResponse<Texpand = unknown> = Required<UsersRecord> &
  AuthSystemFields<Texpand>;

// Types containing all Records and Responses, useful for creating typing helper functions

export type CollectionRecords = {
  config: ConfigRecord;
  discgolf_course: DiscgolfCourseRecord;
  discgolf_infos: DiscgolfInfosRecord;
  images: ImagesRecord;
  items: ItemsRecord;
  locations: LocationsRecord;
  matchups: MatchupsRecord;
  orders: OrdersRecord;
  players: PlayersRecord;
  pool_elements: PoolElementsRecord;
  pools: PoolsRecord;
  roles: RolesRecord;
  slots: SlotsRecord;
  slotted_matchups: SlottedMatchupsRecord;
  spirit: SpiritRecord;
  stats_playlists: StatsPlaylistsRecord;
  team_registrations: TeamRegistrationsRecord;
  teams: TeamsRecord;
  tournament_infos: TournamentInfosRecord;
  users: UsersRecord;
};

export type CollectionResponses = {
  config: ConfigResponse;
  discgolf_course: DiscgolfCourseResponse;
  discgolf_infos: DiscgolfInfosResponse;
  images: ImagesResponse;
  items: ItemsResponse;
  locations: LocationsResponse;
  matchups: MatchupsResponse;
  orders: OrdersResponse;
  players: PlayersResponse;
  pool_elements: PoolElementsResponse;
  pools: PoolsResponse;
  roles: RolesResponse;
  slots: SlotsResponse;
  slotted_matchups: SlottedMatchupsResponse;
  spirit: SpiritResponse;
  stats_playlists: StatsPlaylistsResponse;
  team_registrations: TeamRegistrationsResponse;
  teams: TeamsResponse;
  tournament_infos: TournamentInfosResponse;
  users: UsersResponse;
};

// Type for usage with type asserted PocketBase instance
// https://github.com/pocketbase/js-sdk#specify-typescript-definitions

export type TypedPocketBase = PocketBase & {
  collection(idOrName: 'config'): RecordService<ConfigResponse>;
  collection(
    idOrName: 'discgolf_course',
  ): RecordService<DiscgolfCourseResponse>;
  collection(idOrName: 'discgolf_infos'): RecordService<DiscgolfInfosResponse>;
  collection(idOrName: 'images'): RecordService<ImagesResponse>;
  collection(idOrName: 'items'): RecordService<ItemsResponse>;
  collection(idOrName: 'locations'): RecordService<LocationsResponse>;
  collection(idOrName: 'matchups'): RecordService<MatchupsResponse>;
  collection(idOrName: 'orders'): RecordService<OrdersResponse>;
  collection(idOrName: 'players'): RecordService<PlayersResponse>;
  collection(idOrName: 'pool_elements'): RecordService<PoolElementsResponse>;
  collection(idOrName: 'pools'): RecordService<PoolsResponse>;
  collection(idOrName: 'roles'): RecordService<RolesResponse>;
  collection(idOrName: 'slots'): RecordService<SlotsResponse>;
  collection(
    idOrName: 'slotted_matchups',
  ): RecordService<SlottedMatchupsResponse>;
  collection(idOrName: 'spirit'): RecordService<SpiritResponse>;
  collection(
    idOrName: 'stats_playlists',
  ): RecordService<StatsPlaylistsResponse>;
  collection(
    idOrName: 'team_registrations',
  ): RecordService<TeamRegistrationsResponse>;
  collection(idOrName: 'teams'): RecordService<TeamsResponse>;
  collection(
    idOrName: 'tournament_infos',
  ): RecordService<TournamentInfosResponse>;
  collection(idOrName: 'users'): RecordService<UsersResponse>;
};
